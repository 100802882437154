import {BasicsClass} from "@/common/BasicsClass";
import {Util} from "@/common/util";

export default class CardManagementAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['list', {
            url: '/shopMember/index',
            name: 'list',
            label: '列表'
        }],
        ['export', {
            url: '/shopMember/exportUser',
            name: 'export',
            label: '导出列表'
        }],
    ])

    // 单店卡用户管理
    public card_management(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('list').url

        const start_date: string = Util.handleStartTimeAndEndTime(params.time, false).startTime
        const end_date: string = Util.handleStartTimeAndEndTime(params.time, false).endTime
        this.BasicPost(
            url,
            {
                ...params,
                start_date: start_date,
                end_date: end_date
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    // 单店卡用户管理导出
    public export_card_management(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('export').url
        const start_date: string = Util.handleStartTimeAndEndTime(params.time, false).startTime
        const end_date: string = Util.handleStartTimeAndEndTime(params.time, false).endTime
        this.BasicPost(
            url,
            {
                ...params,
                start_date: start_date,
                end_date: end_date
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
}
